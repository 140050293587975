import script from "./ReplaceModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./ReplaceModal.vue?vue&type=script&setup=true&lang=ts"

import "./ReplaceModal.vue?vue&type=style&index=0&id=79191158&lang=scss"
/* custom blocks */
import block0 from "./ReplaceModal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__