export const colorPalette256: readonly string[] = [
    '#FFFFFF00',
    '#000000',
    '#FFFFFF',
    '#808080',
    '#FF0000',
    '#E60000',
    '#CC3333',
    '#BF6060',
    '#997373',
    '#800000',
    '#731D1D',
    '#663333',
    '#4C0000',
    '#FF342D',
    '#FF7070',
    '#FFA6A6',
    '#FFD9D9',
    '#FF4800',
    '#CC3A00',
    '#B25936',
    '#B28C7D',
    '#661D00',
    '#664133',
    '#FF7640',
    '#FFA480',
    '#FFD1BF',
    '#FF8000',
    '#E67300',
    '#CC853D',
    '#BFA386',
    '#994C00',
    '#806040',
    '#4C2600',
    '#FF9C38',
    '#FFB870',
    '#FFD2A6',
    '#FFECD9',
    '#FFAA00',
    '#E69900',
    '#CC9C3D',
    '#B2A17D',
    '#805500',
    '#665533',
    '#FFBF40',
    '#FFD580',
    '#FFEABF',
    '#FFD500',
    '#E6BF00',
    '#CCB43D',
    '#B2AA7D',
    '#736000',
    '#665E33',
    '#FFDF40',
    '#FFEA80',
    '#FFF4BF',
    '#FFFF00',
    '#E6E600',
    '#CCCC3D',
    '#BFBF86',
    '#999900',
    '#808020',
    '#666633',
    '#4C4C00',
    '#FFFF38',
    '#FFFF70',
    '#FFFFA6',
    '#FFFFD9',
    '#D4FF00',
    '#BFE600',
    '#B4CC3D',
    '#AAB27D',
    '#556600',
    '#464C26',
    '#DFFF40',
    '#EAFF80',
    '#F4FFBF',
    '#9DFF00',
    '#8EE600',
    '#95CC3D',
    '#9EB27D',
    '#477300',
    '#526633',
    '#B6FF40',
    '#CEFF80',
    '#E7FFBF',
    '#55FF00',
    '#4CE600',
    '#6DCC3D',
    '#8FB27D',
    '#267300',
    '#446633',
    '#7FFF40',
    '#AAFF80',
    '#D4FFBF',
    '#00FF00',
    '#00E600',
    '#33CC33',
    '#60BF60',
    '#8FBF8F',
    '#009900',
    '#208020',
    '#336633',
    '#004C00',
    '#38FF38',
    '#70FF70',
    '#A6FFA6',
    '#D9FFD9',
    '#00FF6A',
    '#00E660',
    '#3DCC79',
    '#7DB293',
    '#007330',
    '#336648',
    '#30FF67',
    '#80FFB5',
    '#BFFFDA',
    '#00FFBF',
    '#00E6AC',
    '#3DCCA8',
    '#86BFB1',
    '#008C69',
    '#408070',
    '#004C39',
    '#38FFCD',
    '#70FFDB',
    '#A6FFE9',
    '#D9FFF5',
    '#00FFFF',
    '#00E6E6',
    '#3DCCCC',
    '#86BFBF',
    '#009999',
    '#208080',
    '#336666',
    '#004C4C',
    '#38FFFF',
    '#70FFFF',
    '#A6FFFF',
    '#D9FFFF',
    '#00D4FF',
    '#00BFE6',
    '#3DB4CC',
    '#7DAAB2',
    '#006073',
    '#335D66',
    '#40DFFF',
    '#80EAFF',
    '#BFF4FF',
    '#00AAFF',
    '#0099E6',
    '#3D9CCC',
    '#7DA1B2',
    '#004C73',
    '#335566',
    '#40BFFF',
    '#80D4FF',
    '#BFEAFF',
    '#0080FF',
    '#0066CC',
    '#3D85CC',
    '#86A3BF',
    '#004C99',
    '#406080',
    '#00264C',
    '#389CFF',
    '#70B8FF',
    '#A6D2FF',
    '#D9ECFF',
    '#004CFF',
    '#0045E6',
    '#3D68CC',
    '#7D8DB2',
    '#002680',
    '#334266',
    '#4079FF',
    '#80A6FF',
    '#BFD2FF',
    '#0000FF',
    '#0000E6',
    '#3333CC',
    '#6060BF',
    '#737399',
    '#000080',
    '#1D1D73',
    '#333366',
    '#00004C',
    '#3838FF',
    '#7070FF',
    '#A6A6FF',
    '#D9D9FF',
    '#5500FF',
    '#4C00E6',
    '#6D3DCC',
    '#8F7DB2',
    '#2A0080',
    '#443366',
    '#7F40FF',
    '#AA80FF',
    '#D4BFFF',
    '#8C00FF',
    '#7E00E6',
    '#8C3DCC',
    '#A586BF',
    '#540099',
    '#634080',
    '#2A004C',
    '#A538FF',
    '#BF70FF',
    '#D7A6FF',
    '#EED9FF',
    '#C300FF',
    '#B000E6',
    '#AB3DCC',
    '#A67DB2',
    '#620080',
    '#5A3366',
    '#D240FF',
    '#E180FF',
    '#F0BFFF',
    '#FF00FF',
    '#E600E6',
    '#CC3DCC',
    '#BF86BF',
    '#990099',
    '#802080',
    '#663366',
    '#4C004C',
    '#FF38FF',
    '#FF70FF',
    '#FFA6FF',
    '#FFD9FF',
    '#FF00B2',
    '#E600A1',
    '#CC3DA1',
    '#B27DA2',
    '#800059',
    '#663357',
    '#FF40C6',
    '#FF80D9',
    '#FFBFEC',
    '#FF0066',
    '#E6005C',
    '#CC3D76',
    '#BF869D',
    '#99003D',
    '#804059',
    '#4C001F',
    '#FF3888',
    '#FF70A9',
    '#FFA6C9',
    '#FFD9E8',
    '#333333',
    '#595959',
    '#9E9E9E',
    '#BFBFBF',
    '#E0E0E0',
];
