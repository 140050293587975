export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "zh": {
        "复制": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
        "粘贴": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粘贴"])},
        "选择文件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件"])},
        "清空": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清空"])},
        "复制粘贴不支持": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持，请手动复制"])},
        "保存文件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存文件"])},
        "批量替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量替换"])},
        "撤销": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤销"])},
        "重做": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重做"])},
        "自动选择语言": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动选择"])}
      },
      "en": {
        "复制": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "粘贴": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste"])},
        "选择文件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select File"])},
        "清空": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
        "复制粘贴不支持": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not supported, please copy manually"])},
        "保存文件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save File"])},
        "批量替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch Replace"])},
        "撤销": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
        "重做": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redo"])},
        "自动选择语言": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Select"])}
      }
    }
  })
}
