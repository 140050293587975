export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "zh": {
        "模式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模式"])},
        "送入轨道": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送入轨道"])}
      },
      "en": {
        "模式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
        "送入轨道": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orbit"])}
      },
      "fr": {
        "模式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
        "送入轨道": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orbit"])}
      }
    }
  })
}
