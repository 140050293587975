import { Tech } from "./types";

export const tech: Tech[] = [
    {id: 1},
    {id: 1001},
    {id: 1002},
    {id: 1101},
    {id: 1102},
    {id: 1103},
    {id: 1104},
    {id: 1111},
    {id: 1112},
    {id: 1113},
    {id: 1114},
    {id: 1120},
    {id: 1121},
    {id: 1122},
    {id: 1123},
    {id: 1124},
    {id: 1125},
    {id: 1126},
    {id: 1131},
    {id: 1132},
    {id: 1133},
    {id: 1134},
    {id: 1141},
    {id: 1142},
    {id: 1143},
    {id: 1144},
    {id: 1145},
    {id: 1151},
    {id: 1152},
    {id: 1153},
    {id: 1201},
    {id: 1202},
    {id: 1203},
    {id: 1302},
    {id: 1303},
    {id: 1304},
    {id: 1305},
    {id: 1311},
    {id: 1312},
    {id: 1401},
    {id: 1402},
    {id: 1403},
    {id: 1411},
    {id: 1412},
    {id: 1413},
    {id: 1414},
    {id: 1415},
    {id: 1416},
    {id: 1417},
    {id: 1501},
    {id: 1502},
    {id: 1503},
    {id: 1504},
    {id: 1505},
    {id: 1506},
    {id: 1507},
    {id: 1508},
    {id: 1511},
    {id: 1512},
    {id: 1513},
    {id: 1521},
    {id: 1522},
    {id: 1523},
    {id: 1601},
    {id: 1602},
    {id: 1603},
    {id: 1604},
    {id: 1605},
    {id: 1606},
    {id: 1607},
    {id: 1608},
    {id: 1701},
    {id: 1702},
    {id: 1703},
    {id: 1704},
    {id: 1705},
    {id: 1711},
    {id: 1712},
    {id: 1801},
    {id: 1802},
    {id: 1803},
    {id: 1804},
    {id: 1805},
    {id: 1806},
    {id: 1807},
    {id: 1808},
    {id: 1809},
    {id: 1810},
    {id: 1811},
    {id: 1812},
    {id: 1813},
    {id: 1814},
    {id: 1815},
    {id: 1816},
    {id: 1817},
    {id: 1818},
    {id: 1819},
    {id: 1820},
    {id: 1821},
    {id: 1822},
    {id: 1823},
    {id: 1824},
    {id: 1826},
    {id: 1901},
    {id: 1902},
    {id: 1903},
    {id: 1904},
    {id: 2101},
    {id: 2102},
    {id: 2103},
    {id: 2104},
    {id: 2105},
    {id: 2106},
    {id: 2201},
    {id: 2202},
    {id: 2203},
    {id: 2204},
    {id: 2205},
    {id: 2206},
    {id: 2207},
    {id: 2208},
    {id: 2301},
    {id: 2302},
    {id: 2303},
    {id: 2304},
    {id: 2305},
    {id: 2306},
    {id: 2307},
    {id: 2401},
    {id: 2402},
    {id: 2403},
    {id: 2404},
    {id: 2405},
    {id: 2406},
    {id: 2407},
    {id: 2501},
    {id: 2502},
    {id: 2503},
    {id: 2504},
    {id: 2505},
    {id: 2506},
    {id: 2601},
    {id: 2602},
    {id: 2603},
    {id: 2604},
    {id: 2605},
    {id: 2606},
    {id: 2701},
    {id: 2702},
    {id: 2703},
    {id: 2704},
    {id: 2705},
    {id: 2801},
    {id: 2802},
    {id: 2803},
    {id: 2804},
    {id: 2805},
    {id: 2806},
    {id: 2807},
    {id: 2901},
    {id: 2902},
    {id: 2903},
    {id: 2904},
    {id: 2905},
    {id: 2906},
    {id: 2951},
    {id: 2952},
    {id: 2953},
    {id: 2954},
    {id: 2955},
    {id: 2956},
    {id: 3101},
    {id: 3102},
    {id: 3103},
    {id: 3104},
    {id: 3105},
    {id: 3106},
    {id: 3201},
    {id: 3202},
    {id: 3203},
    {id: 3204},
    {id: 3205},
    {id: 3206},
    {id: 3207},
    {id: 3208},
    {id: 3301},
    {id: 3302},
    {id: 3303},
    {id: 3304},
    {id: 3305},
    {id: 3306},
    {id: 3311},
    {id: 3312},
    {id: 3313},
    {id: 3314},
    {id: 3315},
    {id: 3316},
    {id: 4001},
    {id: 4002},
    {id: 4003},
    {id: 4004},
    {id: 4005},
    {id: 3401},
    {id: 3402},
    {id: 3403},
    {id: 3404},
    {id: 3405},
    {id: 3406},
    {id: 3407},
    {id: 3501},
    {id: 3502},
    {id: 3503},
    {id: 3504},
    {id: 3505},
    {id: 3506},
    {id: 3507},
    {id: 3508},
    {id: 3509},
    {id: 3510},
    {id: 3801},
    {id: 3802},
    {id: 3803},
    {id: 3601},
    {id: 3602},
    {id: 3603},
    {id: 3604},
    {id: 3605},
    {id: 3606},
    {id: 3701},
    {id: 3702},
    {id: 3703},
    {id: 3704},
    {id: 3705},
    {id: 3706},
    {id: 3901},
    {id: 3902},
    {id: 3903},
    {id: 3904},
    {id: 4101},
    {id: 4102},
    {id: 4103},
    {id: 4104},
    {id: 5001},
    {id: 5002},
    {id: 5003},
    {id: 5004},
    {id: 5005},
    {id: 5006},
    {id: 5101},
    {id: 5102},
    {id: 5103},
    {id: 5104},
    {id: 5105},
    {id: 5106},
    {id: 5201},
    {id: 5202},
    {id: 5203},
    {id: 5204},
    {id: 5205},
    {id: 5206},
    {id: 5301},
    {id: 5302},
    {id: 5303},
    {id: 5304},
    {id: 5305},
    {id: 5401},
    {id: 5402},
    {id: 5403},
    {id: 5404},
    {id: 5405},
    {id: 5601},
    {id: 5602},
    {id: 5603},
    {id: 5604},
    {id: 5605},
    {id: 5701},
    {id: 5702},
    {id: 5703},
    {id: 5704},
    {id: 5705},
    {id: 5801},
    {id: 5802},
    {id: 5803},
    {id: 5804},
    {id: 5805},
    {id: 5806},
    {id: 5807},
    {id: 5901},
    {id: 5902},
    {id: 5903},
    {id: 5904},
    {id: 5905},
    {id: 5906},
    {id: 5907},
    {id: 6001},
    {id: 6002},
    {id: 6003},
    {id: 6004},
    {id: 6005},
    {id: 6006},
    {id: 6101},
    {id: 6102},
    {id: 6103},
    {id: 6104},
    {id: 6105},
    {id: 6106},
];
