export default {
  "X射线裂解": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X射线裂解"])},
  "临界光子": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["临界光子"])},
  "人造恒星": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人造恒星"])},
  "位面熔炉": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位面熔炉"])},
  "位面过滤器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位面过滤器"])},
  "低速传送带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["传送带"])},
  "低速分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分拣器"])},
  "信息矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息矩阵"])},
  "信标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信号塔"])},
  "储液罐": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["储液罐"])},
  "光子合并器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["光子合并器"])},
  "光子合并器（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["光子合并器（高效）"])},
  "光子物质化": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质能储存"])},
  "光栅石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["光栅石"])},
  "分形硅石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分形硅石"])},
  "分馏塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分馏塔"])},
  "制造台 Mk.I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造台 Mk.I"])},
  "制造台 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造台 Mk.II"])},
  "制造台 Mk.III": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造台 Mk.III"])},
  "制造台 Mk.IV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重组式制造台"])},
  "刺笋结晶": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刺笋结晶"])},
  "加力推进器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加力推进器"])},
  "动力引擎": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["动力引擎"])},
  "化工厂": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["化工厂"])},
  "化工厂 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量子化工厂"])},
  "单极磁石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单极磁石"])},
  "卡西米尔晶体": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡西米尔晶体"])},
  "卡西米尔晶体（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡西米尔晶体（高效）"])},
  "卫星配电站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卫星配电站"])},
  "原油": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原油"])},
  "原油精炼厂": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原油精炼厂"])},
  "原油萃取站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原油萃取站"])},
  "反物质": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反物质"])},
  "反物质燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反物质燃料棒"])},
  "反物质胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反物质胶囊"])},
  "可燃冰": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可燃冰"])},
  "喷涂机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷涂机"])},
  "四向分流器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四向分流器"])},
  "地基": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地基"])},
  "地热发电站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地热发电站"])},
  "地面战斗机-A型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精准无人机"])},
  "地面战斗机-E型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原型机"])},
  "地面战斗机-F型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攻击无人机"])},
  "地面电浆炮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近程电浆塔"])},
  "垂直发射井": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["垂直发射井"])},
  "塑料": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塑料"])},
  "增产剂 Mk.I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增产剂 Mk.I"])},
  "增产剂 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增产剂 Mk.II"])},
  "增产剂 Mk.III": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增产剂 Mk.III"])},
  "处理器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理器"])},
  "大型储物仓": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大型储物仓"])},
  "大型采矿机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大型采矿机"])},
  "太空战斗机-A型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["护卫舰"])},
  "太空战斗机-F型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驱逐舰"])},
  "太阳帆": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太阳帆"])},
  "太阳能板": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太阳能板"])},
  "奇异物质": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇异物质"])},
  "存储单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑雾矩阵"])},
  "宇宙矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宇宙矩阵"])},
  "导弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导弹组"])},
  "导弹防御塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导弹防御塔"])},
  "射线接收站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["射线接收站"])},
  "小型储物仓": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小型储物仓"])},
  "小型运载火箭": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小型运载火箭"])},
  "干扰塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["干扰塔"])},
  "引力导弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引力导弹组"])},
  "引力矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引力矩阵"])},
  "引力透镜": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引力透镜"])},
  "微型粒子对撞机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微型粒子对撞机"])},
  "微型聚变发电站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微型聚变发电站"])},
  "微晶元件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微晶元件"])},
  "战场分析基站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["战场分析基站"])},
  "戴森球组件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戴森球组件"])},
  "护盾发生器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行星护盾发生器"])},
  "抽水站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抽水站"])},
  "推进器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - 推进器"])},
  "推进器2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推进器"])},
  "无线输电塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无线输电塔"])},
  "星际物流运输站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星际物流运输站"])},
  "星际物流运输船": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星际物流运输船"])},
  "晶格硅": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晶格硅"])},
  "晶格硅（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晶格硅（高效）"])},
  "晶石炮弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晶石炮弹组"])},
  "晶石爆破单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晶石爆破单元"])},
  "有机晶体": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有机晶体"])},
  "有机晶体（原始）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有机晶体（原始）"])},
  "木材": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木材"])},
  "机枪弹箱": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机枪弹箱"])},
  "极速传送带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["极速传送带"])},
  "极速分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["极速分拣器"])},
  "框架材料": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["框架材料"])},
  "棱镜": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["棱镜"])},
  "植物燃料": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["植物燃料"])},
  "氘核燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氘核燃料棒"])},
  "氢": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氢"])},
  "氢燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["液氢燃料棒"])},
  "水": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水"])},
  "流速器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流速监测器"])},
  "湮灭约束球": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湮灭约束球"])},
  "火力发电厂": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火力发电厂"])},
  "炮弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["炮弹组"])},
  "煤矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["煤矿"])},
  "熔炉 Mk.III": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负熵熔炉"])},
  "燃烧单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃烧单元"])},
  "爆破单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["爆破单元"])},
  "物流运输机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物流运输机"])},
  "物流运输站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行星内物流运输站"])},
  "物流配送器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物流配送器"])},
  "物质重组器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物质重组器"])},
  "玻璃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玻璃"])},
  "电力感应塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电力感应塔"])},
  "电动机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电动机"])},
  "电弧熔炉": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电弧熔炉"])},
  "电浆激发器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电浆激发器"])},
  "电磁压制胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["压制胶囊"])},
  "电磁干扰胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["干扰胶囊"])},
  "电磁涡轮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电磁涡轮"])},
  "电磁矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电磁矩阵"])},
  "电磁轨道弹射器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电磁轨道弹射器"])},
  "电路板": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电路板"])},
  "矩阵研究站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矩阵研究站"])},
  "矩阵研究站 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自演化研究站"])},
  "石墨烯": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石墨烯"])},
  "石墨烯（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石墨烯（高效）"])},
  "石材": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石材"])},
  "石矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石矿"])},
  "硅基神经元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硅基神经元"])},
  "硅石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硅石"])},
  "硫酸": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硫酸"])},
  "碳纳米管": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["碳纳米管"])},
  "碳纳米管（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["碳纳米管（高效）"])},
  "磁化电浆炮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁化电浆炮"])},
  "磁线圈": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁线圈"])},
  "磁铁": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁铁"])},
  "空间翘曲器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空间翘曲器"])},
  "空间翘曲器（高级）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空间翘曲器（高级）"])},
  "等离子精炼": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等离子精炼"])},
  "等离子胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等离子胶囊"])},
  "粒子容器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粒子容器"])},
  "粒子容器（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粒子容器（高效）"])},
  "粒子宽带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粒子宽带"])},
  "精炼油": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精炼油"])},
  "结构矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结构矩阵"])},
  "聚爆加农炮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聚爆加农炮"])},
  "能量枢纽": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["能量枢纽"])},
  "能量矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["能量矩阵"])},
  "能量碎片": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["能量碎片"])},
  "自动集装机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动集装机"])},
  "蓄电器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓄电器"])},
  "蓄电器（满）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓄电器（满）"])},
  "虚粒子": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["核心素"])},
  "负熵奇点": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负熵奇点"])},
  "超合金弹箱": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超合金弹箱"])},
  "超级磁场环": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超级磁场环"])},
  "超音速导弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超音速导弹组"])},
  "轨道采集器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轨道采集器"])},
  "配送运输机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送运输机"])},
  "采矿机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["采矿机"])},
  "重整精炼": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重整精炼"])},
  "重氢": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重氢"])},
  "重氢分馏": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重氢分馏"])},
  "量子芯片": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量子芯片"])},
  "金伯利矿石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金伯利矿石"])},
  "金刚石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金刚石"])},
  "金刚石（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金刚石（高效）"])},
  "金色燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奇异湮灭燃料棒"])},
  "钛化弹箱": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钛化弹箱"])},
  "钛化玻璃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钛化玻璃"])},
  "钛合金": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钛合金"])},
  "钛块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钛块"])},
  "钛晶石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钛晶石"])},
  "钛石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钛石"])},
  "钢材": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钢材"])},
  "铁块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铁块"])},
  "铁矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铁矿"])},
  "铜块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铜块"])},
  "铜矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铜矿"])},
  "集装分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集装分拣器"])},
  "风力涡轮机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风力涡轮机"])},
  "高斯机枪塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高斯机枪塔"])},
  "高爆炮弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高爆炮弹组"])},
  "高纯硅块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高纯硅块"])},
  "高能石墨": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高能石墨"])},
  "高速传送带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高速传送带"])},
  "高速分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高速分拣器"])},
  "高频激光塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高频激光塔"])},
  "齿轮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["齿轮"])},
  "不满足": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不满足"])},
  "不选轨道": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无"])},
  "不限回收类型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回收所有类型"])},
  "低音贝斯": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低音贝斯"])},
  "储液罐关": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关"])},
  "储液罐开": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开"])},
  "储液罐输入": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入"])},
  "储液罐输出": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输出"])},
  "充电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充电"])},
  "光子生成": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["光子生成"])},
  "分拣长度": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长度:"])},
  "创建时间": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建时间"])},
  "创建版本号": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建版本号"])},
  "加速生产": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产加速"])},
  "包含设施": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含设施"])},
  "包括轨道采集器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  轨道采集器"])},
  "十倍射速": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十倍射速"])},
  "吉他": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉他"])},
  "向玩家配送模式0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向伊卡洛斯配送"])},
  "向玩家配送模式1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向伊卡洛斯供应"])},
  "向玩家配送模式2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从伊卡洛斯回收"])},
  "向玩家配送模式3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向伊卡洛斯供应和回收"])},
  "向箱子配送模式0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向其他配送器配送"])},
  "向箱子配送模式1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向其他配送器供应"])},
  "向箱子配送模式2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向其他配送器需求"])},
  "周期秒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 秒"])},
  "图标布局": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图标布局"])},
  "基站建设无人机提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建设无人机"])},
  "基站战斗机提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["战斗无人机"])},
  "基站自动拾取提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动拾取"])},
  "基站自动补充编队提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动补充编队"])},
  "基站自动重建提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动标记重建"])},
  "增产剂效果简": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增产效果"])},
  "声音警报": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["声音警报"])},
  "小号": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小号"])},
  "小提琴": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小提琴"])},
  "建筑公式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建筑"])},
  "建设机优先修理标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优先修理"])},
  "建设机优先建造标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优先建造"])},
  "建设机均衡模式标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均衡模式"])},
  "当前货物数": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前"])},
  "待机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待机"])},
  "循环播放": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["循环"])},
  "放电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放电"])},
  "无": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无"])},
  "无货物响": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无货物响"])},
  "星际仓储": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星际仓储"])},
  "星际供应": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星际供应"])},
  "星际需求": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星际需求"])},
  "曲速启用路程": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曲速启用路程"])},
  "最大充能功率": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大充能功率"])},
  "有货物响": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有货物响"])},
  "未满足且无货物": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未满足且无货物"])},
  "未满足且有货物": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未满足且有货物"])},
  "未满足条件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未满足条件"])},
  "本地仓储": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本地仓储"])},
  "本地供应": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本地供应"])},
  "本地需求": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本地需求"])},
  "梦铃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梦铃"])},
  "流速器生成物品": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成货物"])},
  "流速器生成物品标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成/消耗货物"])},
  "流速器移除物品": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消耗货物"])},
  "满足": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满足"])},
  "满足条件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满足条件"])},
  "玻璃乐器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玻璃"])},
  "电子琴": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子琴"])},
  "百倍发电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["百倍发电"])},
  "监测周期": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监测周期"])},
  "监测条件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监测条件"])},
  "监测设置": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监测设置"])},
  "目标流量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标流量"])},
  "直接发电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接发电"])},
  "矩阵合成": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矩阵合成"])},
  "科研模式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["科研模式"])},
  "空栏位": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空栏位"])},
  "空格米": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 米"])},
  "系统警报": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统警报"])},
  "组件公式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品"])},
  "缩略图文字": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缩略图文字"])},
  "翘曲器必要性": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  翘曲器必备"])},
  "自动化容量限制": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动化输入限制"])},
  "自动补充提示": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动补充运输单位"])},
  "蓝图介绍": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓝图介绍"])},
  "蓝图代码": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓝图代码"])},
  "衰减范围": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["范围"])},
  "警报-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报 1"])},
  "警报-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报 2"])},
  "警报-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报 3"])},
  "警报-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报 4"])},
  "警报-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报 5"])},
  "警报图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报图标"])},
  "警报设置": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报设置"])},
  "贝斯-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贝斯 1"])},
  "贝斯-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贝斯 2"])},
  "货物上限": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上限"])},
  "货物个": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 个"])},
  "货物过滤": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货物过滤"])},
  "货物集装数量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输出货物集装数量"])},
  "过滤器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
  "运输机最远路程": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输机最远路程"])},
  "运输机起送量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输机起送量"])},
  "运输船最远路程": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输船最远路程"])},
  "运输船起送量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输船起送量"])},
  "选择模式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择模式"])},
  "采集速度": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["采集速度"])},
  "钢琴-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钢琴 1"])},
  "钢琴-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钢琴 2"])},
  "铜管乐": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铜管乐"])},
  "铺底-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铺底 1"])},
  "铺底-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铺底 2"])},
  "铺底-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铺底 3"])},
  "集装使用科技上限": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  使用科技上限"])},
  "音乐盒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音乐盒"])},
  "音色": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音色"])},
  "音量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音量"])},
  "音频长度": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时长"])},
  "音高": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音阶"])},
  "额外产出": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外产出"])},
  "风琴-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风琴 1"])},
  "风琴-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风琴 2"])},
  "鼓": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鼓"])}
}