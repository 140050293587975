import { Signal } from "./types";

export const signal: Signal[] = [
    {id: 501, gridPos: [1, 0]},
    {id: 502, gridPos: [1, 1]},
    {id: 503, gridPos: [1, 2]},
    {id: 504, gridPos: [1, 3]},
    {id: 506, gridPos: [1, 4]},
    {id: 507, gridPos: [1, 5]},
    {id: 508, gridPos: [1, 6]},
    {id: 509, gridPos: [1, 7]},
    {id: 510, gridPos: [1, 8]},
    {id: 511, gridPos: [1, 9]},
    {id: 512, gridPos: [1, 10]},
    {id: 513, gridPos: [1, 11]},
    {id: 514, gridPos: [1, 12]},
    {id: 515, gridPos: [1, 13]},
    {id: 516, gridPos: [2, 0]},
    {id: 517, gridPos: [2, 1]},
    {id: 401, gridPos: [0, 0]},
    {id: 402, gridPos: [0, 1]},
    {id: 403, gridPos: [0, 2]},
    {id: 404, gridPos: [0, 3]},
    {id: 405, gridPos: [0, 4]},
    {id: 406, gridPos: [0, 5]},
    {id: 600, gridPos: [3, 0]},
    {id: 601, gridPos: [3, 1]},
    {id: 602, gridPos: [3, 2]},
    {id: 603, gridPos: [3, 3]},
    {id: 604, gridPos: [3, 4]},
    {id: 605, gridPos: [3, 5]},
    {id: 606, gridPos: [3, 6]},
    {id: 607, gridPos: [3, 7]},
    {id: 608, gridPos: [3, 8]},
    {id: 609, gridPos: [3, 9]},
];
