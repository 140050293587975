export default {
  "X射线裂解": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X-ray Cracking"])},
  "临界光子": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical Photon"])},
  "人造恒星": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artificial Star"])},
  "位面熔炉": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plane Smelter"])},
  "位面过滤器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plane Filter"])},
  "低速传送带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conveyor Belt MK.I"])},
  "低速分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter MK.I"])},
  "信息矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information Matrix"])},
  "信标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal Tower"])},
  "储液罐": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage Tank"])},
  "光子合并器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photon Combiner"])},
  "光子合并器（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photon Combiner (advanced)"])},
  "光子物质化": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass-energy Storage"])},
  "光栅石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grating Crystal"])},
  "分形硅石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fractal Silicon"])},
  "分馏塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fractionator"])},
  "制造台 Mk.I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembling Machine Mk.I"])},
  "制造台 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembling Machine Mk.II"])},
  "制造台 Mk.III": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembling Machine Mk.III"])},
  "制造台 Mk.IV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-composing Assembler"])},
  "刺笋结晶": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stalagmite Crystal"])},
  "加力推进器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinforced Thruster"])},
  "动力引擎": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine"])},
  "化工厂": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chemical Plant"])},
  "化工厂 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantum Chemical Plant"])},
  "单极磁石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unipolar Magnet"])},
  "卡西米尔晶体": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casimir Crystal"])},
  "卡西米尔晶体（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casimir Crystal (advanced)"])},
  "卫星配电站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satellite Substation"])},
  "原油": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crude Oil"])},
  "原油精炼厂": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil Refinery"])},
  "原油萃取站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil Extractor"])},
  "反物质": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antimatter"])},
  "反物质燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antimatter Fuel Rod"])},
  "反物质胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antimatter Capsule"])},
  "可燃冰": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire Ice"])},
  "喷涂机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spray Coater"])},
  "四向分流器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Splitter"])},
  "地基": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foundation"])},
  "地热发电站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geothermal Power Station"])},
  "地面战斗机-A型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision Drone"])},
  "地面战斗机-E型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prototype"])},
  "地面战斗机-F型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attack Drone"])},
  "地面电浆炮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SR Plasma Turret"])},
  "垂直发射井": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical Launching Silo"])},
  "塑料": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic"])},
  "增产剂 Mk.I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proliferator Mk.I"])},
  "增产剂 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proliferator Mk.II"])},
  "增产剂 Mk.III": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proliferator Mk.III"])},
  "处理器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processor"])},
  "大型储物仓": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depot MK.II"])},
  "大型采矿机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Mining Machine"])},
  "太空战斗机-A型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corvette"])},
  "太空战斗机-F型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destroyer"])},
  "太阳帆": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar Sail"])},
  "太阳能板": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar Panel"])},
  "奇异物质": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strange Matter"])},
  "存储单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Fog Matrix"])},
  "宇宙矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universe Matrix"])},
  "导弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missile Set"])},
  "导弹防御塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missile Turret"])},
  "射线接收站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ray Receiver"])},
  "小型储物仓": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depot MK.I"])},
  "小型运载火箭": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Carrier Rocket"])},
  "干扰塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jammer Tower"])},
  "引力导弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravity Missile Set"])},
  "引力矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravity Matrix"])},
  "引力透镜": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graviton Lens"])},
  "微型粒子对撞机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miniature Particle Collider"])},
  "微型聚变发电站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mini Fusion Power Plant"])},
  "微晶元件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microcrystalline Component"])},
  "战场分析基站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battlefield Analysis Base"])},
  "戴森球组件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dyson Sphere Component"])},
  "护盾发生器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planetary Shield Generator"])},
  "抽水站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Pump"])},
  "推进器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Thruster"])},
  "推进器2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thruster"])},
  "无线输电塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless Power Tower"])},
  "星际物流运输站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interstellar Logistics Station"])},
  "星际物流运输船": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interstellar Logistics Vessel"])},
  "晶格硅": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crystal Silicon"])},
  "晶格硅（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crystal Silicon (advanced)"])},
  "晶石炮弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crystal Shell Set"])},
  "晶石爆破单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crystal Explosive Unit"])},
  "有机晶体": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organic Crystal"])},
  "有机晶体（原始）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organic Crystal (original)"])},
  "木材": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
  "机枪弹箱": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnum Ammo Box"])},
  "极速传送带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conveyor Belt MK.III"])},
  "极速分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter MK.III"])},
  "框架材料": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Material"])},
  "棱镜": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prism"])},
  "植物燃料": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plant Fuel"])},
  "氘核燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuteron Fuel Rod"])},
  "氢": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydrogen"])},
  "氢燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydrogen Fuel Rod"])},
  "水": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water"])},
  "流速器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic Monitor"])},
  "湮灭约束球": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annihilation Constraint Sphere"])},
  "火力发电厂": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thermal Power Plant"])},
  "炮弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shell Set"])},
  "煤矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coal"])},
  "熔炉 Mk.III": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negentropy Smelter"])},
  "燃烧单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combustible Unit"])},
  "爆破单元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explosive Unit"])},
  "物流运输机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics Drone"])},
  "物流运输站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planetary Logistics Station"])},
  "物流配送器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics Distributor"])},
  "物质重组器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matter Recombinator"])},
  "玻璃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass"])},
  "电力感应塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tesla Tower"])},
  "电动机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric Motor"])},
  "电弧熔炉": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arc Smelter"])},
  "电浆激发器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasma Exciter"])},
  "电磁压制胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppressing Capsule"])},
  "电磁干扰胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamming Capsule"])},
  "电磁涡轮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electromagnetic Turbine"])},
  "电磁矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electromagnetic Matrix"])},
  "电磁轨道弹射器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EM-Rail Ejector"])},
  "电路板": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuit Board"])},
  "矩阵研究站": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix Lab"])},
  "矩阵研究站 Mk.II": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-evolution Lab"])},
  "石墨烯": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphene"])},
  "石墨烯（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphene (advanced)"])},
  "石材": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stone Brick"])},
  "石矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stone"])},
  "硅基神经元": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silicon-based Neuron"])},
  "硅石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silicon Ore"])},
  "硫酸": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfuric Acid"])},
  "碳纳米管": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon Nanotube"])},
  "碳纳米管（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon Nanotube (advanced)"])},
  "磁化电浆炮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasma Turret"])},
  "磁线圈": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnetic Coil"])},
  "磁铁": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnet"])},
  "空间翘曲器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space Warper"])},
  "空间翘曲器（高级）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space Warper (advanced)"])},
  "等离子精炼": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasma Refining"])},
  "等离子胶囊": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasma Capsule"])},
  "粒子容器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particle Container"])},
  "粒子容器（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particle Container (advanced)"])},
  "粒子宽带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particle Broadband"])},
  "精炼油": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refined Oil"])},
  "结构矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure Matrix"])},
  "聚爆加农炮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implosion Cannon"])},
  "能量枢纽": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Exchanger"])},
  "能量矩阵": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Matrix"])},
  "能量碎片": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Shard"])},
  "自动集装机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic Piler"])},
  "蓄电器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accumulator"])},
  "蓄电器（满）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accumulator (full)"])},
  "虚粒子": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core Element"])},
  "负熵奇点": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negentropy Singularity"])},
  "超合金弹箱": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superalloy Ammo Box"])},
  "超级磁场环": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-magnetic Ring"])},
  "超音速导弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supersonic Missile Set"])},
  "轨道采集器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orbital Collector"])},
  "配送运输机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics Bot"])},
  "采矿机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining Machine"])},
  "重整精炼": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reformed Refinement"])},
  "重氢": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuterium"])},
  "重氢分馏": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuterium Fractionation"])},
  "量子芯片": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantum Chip"])},
  "金伯利矿石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimberlite Ore"])},
  "金刚石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamond"])},
  "金刚石（高效）": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamond (advanced)"])},
  "金色燃料棒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strange Annihilation Fuel Rod"])},
  "钛化弹箱": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium Ammo Box"])},
  "钛化玻璃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium Glass"])},
  "钛合金": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium Alloy"])},
  "钛块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium Ingot"])},
  "钛晶石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium Crystal"])},
  "钛石": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium Ore"])},
  "钢材": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steel"])},
  "铁块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iron Ingot"])},
  "铁矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iron Ore"])},
  "铜块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copper Ingot"])},
  "铜矿": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copper Ore"])},
  "集装分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pile Sorter"])},
  "风力涡轮机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Turbine"])},
  "高斯机枪塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gauss Turret"])},
  "高爆炮弹组": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-Explosive Shell Set"])},
  "高纯硅块": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-purity Silicon"])},
  "高能石墨": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energetic Graphite"])},
  "高速传送带": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conveyor Belt MK.II"])},
  "高速分拣器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter MK.II"])},
  "高频激光塔": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laser Turret"])},
  "齿轮": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gear"])},
  "不满足": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail"])},
  "不选轨道": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "不限回收类型": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect all types"])},
  "低音贝斯": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Bass"])},
  "储液罐关": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFF"])},
  "储液罐开": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON"])},
  "储液罐输入": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])},
  "储液罐输出": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
  "充电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge"])},
  "光子生成": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photon Generation"])},
  "分拣长度": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length:"])},
  "创建时间": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation  Time"])},
  "创建版本号": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation  Version"])},
  "加速生产": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production speedup"])},
  "包含设施": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Components"])},
  "包括轨道采集器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Orbital Collector"])},
  "十倍射速": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10x Speed"])},
  "吉他": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guitar"])},
  "向玩家配送模式0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute to Icarus"])},
  "向玩家配送模式1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide to Icarus"])},
  "向玩家配送模式2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect from Icarus"])},
  "向玩家配送模式3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide to and collect from Icarus"])},
  "向箱子配送模式0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute to other distributors"])},
  "向箱子配送模式1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide to other distributors"])},
  "向箱子配送模式2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request from other distributors"])},
  "周期秒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" seconds"])},
  "图标布局": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Layout"])},
  "基站建设无人机提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction Drone"])},
  "基站战斗机提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combat Drone"])},
  "基站自动拾取提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Pickup"])},
  "基站自动补充编队提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Fleet Replenishment"])},
  "基站自动重建提示标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Reconstruction Marking"])},
  "增产剂效果简": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proliferator effect"])},
  "声音警报": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker alarm"])},
  "小号": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trumpet"])},
  "小提琴": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violin"])},
  "建筑公式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
  "建设机优先修理标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritize Repair"])},
  "建设机优先建造标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritize Construction"])},
  "建设机均衡模式标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanced"])},
  "当前货物数": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
  "待机": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idle"])},
  "循环播放": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Loop"])},
  "放电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharge"])},
  "无": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "无货物响": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cargo"])},
  "星际仓储": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote storage"])},
  "星际供应": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote supply"])},
  "星际需求": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote demand"])},
  "曲速启用路程": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warp distance"])},
  "最大充能功率": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Charging Power"])},
  "有货物响": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo pass"])},
  "未满足且无货物": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail and no cargo"])},
  "未满足且有货物": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail and cargo pass"])},
  "未满足条件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail"])},
  "本地仓储": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local storage"])},
  "本地供应": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local supply"])},
  "本地需求": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local demand"])},
  "梦铃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dream Bell"])},
  "流速器生成物品": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate cargo"])},
  "流速器生成物品标题": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate/Consume cargo"])},
  "流速器移除物品": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consume cargo"])},
  "满足": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
  "满足条件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
  "玻璃乐器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass"])},
  "电子琴": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyboard"])},
  "百倍发电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100x Gen"])},
  "监测周期": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle"])},
  "监测条件": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
  "监测设置": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor Setting"])},
  "目标流量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target flow"])},
  "直接发电": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Generation"])},
  "矩阵合成": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
  "科研模式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research"])},
  "空栏位": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty slot"])},
  "空格米": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" m"])},
  "系统警报": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global alarm"])},
  "组件公式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
  "缩略图文字": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbnail Text"])},
  "翘曲器必要性": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Warpers required"])},
  "自动化容量限制": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit for Automation Input"])},
  "自动补充提示": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically deploy transport devices"])},
  "蓝图介绍": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blueprint  Description"])},
  "蓝图代码": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blueprint code"])},
  "衰减范围": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range"])},
  "警报-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm 1"])},
  "警报-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm 2"])},
  "警报-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm 3"])},
  "警报-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm 4"])},
  "警报-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm 5"])},
  "警报图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm Icon"])},
  "警报设置": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm settings"])},
  "贝斯-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bass 1"])},
  "贝斯-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bass 2"])},
  "货物上限": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "货物个": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" cargos"])},
  "货物过滤": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item filter"])},
  "货物集装数量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing integration count"])},
  "过滤器": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "运输机最远路程": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drone transport range"])},
  "运输机起送量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Load of Drones"])},
  "运输船最远路程": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vessel transport range"])},
  "运输船起送量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Load of Vessels"])},
  "选择模式": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Mode"])},
  "采集速度": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecting Speed"])},
  "钢琴-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano 1"])},
  "钢琴-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano 2"])},
  "铜管乐": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brass"])},
  "铺底-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pad 1"])},
  "铺底-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pad 2"])},
  "铺底-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pad 3"])},
  "集装使用科技上限": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Up to tech limit"])},
  "音乐盒": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music Box"])},
  "音色": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])},
  "音量": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "音频长度": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
  "音高": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitch"])},
  "额外产出": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra products"])},
  "风琴-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ 1"])},
  "风琴-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ 2"])},
  "鼓": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drumpad"])}
}