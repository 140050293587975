export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "zh": {
        "批量替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量替换"])},
        "搜索：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索："])},
        "替换：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替换："])},
        "范围：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["范围："])},
        "配方": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配方"])},
        "分拣器筛选": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分拣器筛选"])},
        "物流塔栏位": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物流塔栏位"])},
        "传送带图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["传送带图标"])},
        "蓝图图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓝图图标"])},
        "全部替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部替换"])}
      },
      "en": {
        "批量替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch Replace"])},
        "搜索：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search:"])},
        "替换：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace:"])},
        "范围：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope:"])},
        "配方": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipe"])},
        "分拣器筛选": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter"])},
        "物流塔栏位": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Station"])},
        "传送带图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belt Icon"])},
        "蓝图图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blueprint Icon"])},
        "全部替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace All"])}
      },
      "fr": {
        "批量替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer en vrac"])},
        "搜索：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche:"])},
        "替换：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer:"])},
        "范围：": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portée:"])},
        "配方": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recette"])},
        "分拣器筛选": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trieuse"])},
        "物流塔栏位": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Station"])},
        "传送带图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icône de ceinture"])},
        "蓝图图标": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icône de plan"])},
        "全部替换": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout remplacer"])}
      }
    }
  })
}
