import script from "./BuildingOverview.vue?vue&type=script&lang=ts&setup=true"
export * from "./BuildingOverview.vue?vue&type=script&lang=ts&setup=true"

import "./BuildingOverview.vue?vue&type=style&index=0&id=007c1fa4&lang=css"
/* custom blocks */
import block0 from "./BuildingOverview.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__